import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Box, Flex, Text, Link } from "rebass"
import styled from "styled-components"
import {
  FaBeer,
  FaFacebookSquare,
  FaLocationArrow,
  FaInstagram,
  FaPhone,
} from "react-icons/fa"

const StyledFooter = styled(Flex)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  border-top: 3px solid #2f8996;
  padding: 16px;
  color: black;
  background-color: white;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
`

const Location = styled(Flex)``

const StyledText = styled(Text)`
  font-family: "abeatbykai";
  text-transform: lowercase;
  text-decoration: none;
  transition: all 200ms linear;
  color: #363636;
`

const NavLink = styled(AnchorLink)`
  font-family: "abeatbykai";
  text-transform: lowercase;
  text-decoration: none;
  transition: all 200ms linear;
  color: #363636;

  &:hover {
    color: #2f8996;
  }
`

const OutLink = styled(Link)`
  font-family: "abeatbykai";
  text-transform: lowercase;
  text-decoration: none;
  transition: all 200ms linear;
  color: #363636;

  &:hover {
    color: #2f8996;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
      justify-content: center;
    }
  }

  > div {
    &:not(:last-of-type) {
      padding-bottom: 8px;
    }
  }
`

const Footer = () => (
  <StyledFooter as="footer">
    <StyledFlex
      width="100%"
      justifyContent="space-evenly"
      alignContent="center"
      alignItems="center"
      maxWidth={1024}
      margin="auto"
    >
      <Location alignItems="center">
        <Box width={10} />
        <OutLink
          href="https://www.facebook.com/millennium.metals"
          target="_blank"
        >
          <FaFacebookSquare size={24} />
        </OutLink>
        <OutLink
          href="https://www.instagram.com/millennium_metals_ltd"
          target="_blank"
        >
          <FaInstagram size={24} />
        </OutLink>
      </Location>
      <Location alignItems="center">
        <FaPhone />
        <Box width={10} />
        <StyledText>636-8209/9314</StyledText>
      </Location>
      <Location alignItems="center">
        <FaLocationArrow />
        <Box width={10} />
        <StyledText href="#services">
          <Box>
            <Text>Andrew Lane, Mausica</Text>
            <Text>67-69 Southern Main Road, Savonetta, Point Lisas</Text>
          </Box>
        </StyledText>
      </Location>
    </StyledFlex>
  </StyledFooter>
)

export default Footer
