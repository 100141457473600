import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Flex } from "rebass"
import styled from "styled-components"

const NavLink = styled(AnchorLink)`
  font-family: "abeatbykai";
  text-transform: lowercase;
  text-decoration: none;
  transition: all 200ms linear;
  color: #363636;

  &:hover {
    color: #2f8996;
  }
`

const StyledHeader = styled(Flex)`
  z-index: 1000;
  position: fixed;
  top: 0;
  border-bottom: 3px solid #2f8996;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  z-index: 1000;
  position: fixed;
  top: 0;
  border-bottom: 3px solid #2f8996;
  padding: 16px;
  color: black;
  background-color: white;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
`

const Header = () => (
  <StyledHeader as="header">
    <Flex
      width="100%"
      justifyContent="space-evenly"
      maxWidth={1024}
      margin="auto"
    >
      <NavLink href="#home">Home</NavLink>
      <NavLink href="#about">About</NavLink>
      <NavLink href="#services">Services</NavLink>
      <NavLink href="#contact">Contact</NavLink>
    </Flex>
  </StyledHeader>
)

export default Header
