import { createGlobalStyle } from "styled-components"
import "../assets/fonts/abeatbykai.woff"
import "../assets/fonts/abeatbykai.woff2"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "abeatbykai";
    src: url("../assets/fonts/abeatbykai.woff2") format("woff2"), url("../assets/fonts/abeatbykai.woff") format("woff");
    font-display: swap;
  }
`

export default GlobalStyles
